/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line no-unused-vars
import React, { useContext } from "react"
import { Link } from "gatsby"
import { Location } from "@reach/router"

import HeaderContext from "../context/header-context"
import Container from "../components/container"
import Heading from "../components/heading"
import { motion } from "framer-motion"
import transition from "../utils/framer-transition"

import home from "../images/home.svg"
import back from "../images/back.svg"
import burger from "../images/burger.svg"
import close from "../images/close.svg"

const variants = {
  initial: { y: -60, opacity: 0 },
  enter: { y: 0, opacity: 1, transition },
  exit: {
    y: -60,
    opacity: 0,
    transition: { ...transition },
  },
}

const Header = () => {
  const {
    headerState: {
      inverseHeaderColor,
      isHome,
      prevPath,
      section,
      sectionLink,
      menuLinkHoverColor,
      standaloneHeader,
    },
  } = useContext(HeaderContext)

  return (
    <motion.header
      variants={variants}
      sx={{
        position: standaloneHeader ? "inherit" : "absolute",
        top: "0px",
        right: "0px",
        left: "0px",
        color: inverseHeaderColor ? "text" : "background",
        py: 0,
        zIndex: 2,
      }}
    >
      <Container>
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div sx={{ display: "flex" }}>
            {isHome ? (
              <Heading
                hLevel={1}
                headingSx={{
                  fontSize: 1,
                  margin: "0px",
                  opacity: 1,
                }}
                noAnimate
                label="Mutual"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="309"
                  height="52"
                  fill="none"
                  viewBox="0 0 309 52"
                  style={{
                    height: "16px",
                    width: "auto",
                  }}
                >
                  <path
                    fill="currentColor"
                    d="M0 0h.38L22.7 32.511 45.02 0h.38v50.794h-6.475V19.508L22.7 42.839 6.475 19.354v31.44H0V0zM65.032 1.224h6.475v31.134c0 2.092.304 3.953.914 5.585.61 1.632 1.447 3.034 2.514 4.207a11.838 11.838 0 003.885 2.601c1.523.612 3.174.918 4.951.918 1.727 0 3.326-.306 4.799-.918 1.524-.612 2.819-1.479 3.885-2.6 1.117-1.174 1.98-2.576 2.59-4.208.61-1.632.914-3.493.914-5.584V1.223h6.475v32.053a20.5 20.5 0 01-1.219 7.037c-.762 2.244-1.93 4.208-3.504 5.89-1.574 1.684-3.53 3.035-5.865 4.055-2.286.969-4.977 1.453-8.075 1.453-3.047 0-5.739-.484-8.075-1.453-2.336-1.02-4.29-2.371-5.865-4.054-1.574-1.683-2.768-3.647-3.58-5.89a20.512 20.512 0 01-1.22-7.038V1.224zM117.488 1.224h36.259v5.89h-14.854v43.68h-6.475V7.114h-14.93v-5.89zM168.865 1.224h6.475v31.134c0 2.092.304 3.953.914 5.585.609 1.632 1.447 3.034 2.514 4.207a11.84 11.84 0 003.884 2.601c1.524.612 3.174.918 4.952.918 1.727 0 3.326-.306 4.799-.918 1.523-.612 2.818-1.479 3.885-2.6 1.117-1.174 1.98-2.576 2.59-4.208.609-1.632.914-3.493.914-5.584V1.223h6.475v32.053c0 2.447-.407 4.793-1.219 7.037-.762 2.244-1.93 4.208-3.504 5.89-1.574 1.684-3.53 3.035-5.866 4.055-2.285.969-4.976 1.453-8.074 1.453-3.047 0-5.739-.484-8.075-1.453-2.336-1.02-4.291-2.371-5.865-4.054-1.574-1.683-2.768-3.647-3.58-5.89a20.5 20.5 0 01-1.219-7.038V1.224zM241.452.306h1.143l20.72 50.489h-6.78l-4.19-10.251h-20.567l-4.037 10.25h-6.856L241.452.306zm-9.674 40.238h20.567L241.909 14.84l-10.131 25.703zM279.482 1.224h6.474v43.527h22.472v6.044h-28.946V1.224z"
                  ></path>
                </svg>
              </Heading>
            ) : (
              <Link
                sx={{
                  textDecoration: "none",
                }}
                to="/"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="309"
                  height="52"
                  fill="none"
                  viewBox="0 0 309 52"
                  style={{
                    height: "16px",
                    width: "auto",
                  }}
                >
                  <path
                    fill="currentColor"
                    d="M0 0h.38L22.7 32.511 45.02 0h.38v50.794h-6.475V19.508L22.7 42.839 6.475 19.354v31.44H0V0zM65.032 1.224h6.475v31.134c0 2.092.304 3.953.914 5.585.61 1.632 1.447 3.034 2.514 4.207a11.838 11.838 0 003.885 2.601c1.523.612 3.174.918 4.951.918 1.727 0 3.326-.306 4.799-.918 1.524-.612 2.819-1.479 3.885-2.6 1.117-1.174 1.98-2.576 2.59-4.208.61-1.632.914-3.493.914-5.584V1.223h6.475v32.053a20.5 20.5 0 01-1.219 7.037c-.762 2.244-1.93 4.208-3.504 5.89-1.574 1.684-3.53 3.035-5.865 4.055-2.286.969-4.977 1.453-8.075 1.453-3.047 0-5.739-.484-8.075-1.453-2.336-1.02-4.29-2.371-5.865-4.054-1.574-1.683-2.768-3.647-3.58-5.89a20.512 20.512 0 01-1.22-7.038V1.224zM117.488 1.224h36.259v5.89h-14.854v43.68h-6.475V7.114h-14.93v-5.89zM168.865 1.224h6.475v31.134c0 2.092.304 3.953.914 5.585.609 1.632 1.447 3.034 2.514 4.207a11.84 11.84 0 003.884 2.601c1.524.612 3.174.918 4.952.918 1.727 0 3.326-.306 4.799-.918 1.523-.612 2.818-1.479 3.885-2.6 1.117-1.174 1.98-2.576 2.59-4.208.609-1.632.914-3.493.914-5.584V1.223h6.475v32.053c0 2.447-.407 4.793-1.219 7.037-.762 2.244-1.93 4.208-3.504 5.89-1.574 1.684-3.53 3.035-5.866 4.055-2.285.969-4.976 1.453-8.074 1.453-3.047 0-5.739-.484-8.075-1.453-2.336-1.02-4.291-2.371-5.865-4.054-1.574-1.683-2.768-3.647-3.58-5.89a20.5 20.5 0 01-1.219-7.038V1.224zM241.452.306h1.143l20.72 50.489h-6.78l-4.19-10.251h-20.567l-4.037 10.25h-6.856L241.452.306zm-9.674 40.238h20.567L241.909 14.84l-10.131 25.703zM279.482 1.224h6.474v43.527h22.472v6.044h-28.946V1.224z"
                  ></path>
                </svg>
              </Link>
            )}

            {section && (
              <div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: "1em",
                  fontSize: "0.65em",
                  textTransform: "uppercase",
                  opacity: "0.5",
                }}
              >
                {sectionLink ? (
                  <Link
                    sx={{
                      textDecoration: "none",
                    }}
                    to={sectionLink}
                  >
                    {section}
                  </Link>
                ) : (
                  <Heading
                    hLevel={1}
                    headingSx={{
                      fontSize: 1,
                      margin: "0px",
                      opacity: 1,
                    }}
                    noAnimate
                  >
                    {section}
                  </Heading>
                )}
              </div>
            )}
          </div>
          {prevPath ? (
            <Link
              sx={{
                variant: "text.link",
                "&:hover": {
                  color: menuLinkHoverColor,
                },
              }}
              to={prevPath}
            >
              Close
            </Link>
          ) : (
            <Location>
              {({ location }) => (
                <Link
                  to="/menu"
                  sx={{
                    variant: "text.link",
                    "&:hover": {
                      color: menuLinkHoverColor,
                    },
                  }}
                  state={{ prevPath: location.pathname }}
                >
                  Menu
                </Link>
              )}
            </Location>
          )}
        </div>
      </Container>
    </motion.header>
  )
}

export default Header
