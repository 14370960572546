/** @jsx jsx */
import { jsx } from "theme-ui"
import { motion } from "framer-motion"

import transition from "../utils/framer-transition"

const Heading = ({
  children,
  headingSx,
  hLevel,
  fullOpacity,
  noAnimate,
  label,
}) => {
  const Tag = `h${hLevel}`

  const InnerTag = noAnimate ? `span` : motion.span

  const textMotionVariants = {
    initial: { x: -60, opacity: 0 },
    enter: { x: 0, opacity: 1, transition },
    exit: {
      x: -60,
      opacity: 0,
      transition: { ...transition },
    },
  }

  return (
    <Tag
      sx={{
        marginBottom: "1.5em",
        fontSize: hLevel === 1 ? "2.1em" : hLevel === 2 ? "1.5em" : "1em",
        lineHeight: "1.3em",
        fontWeight: "600",
        opacity: fullOpacity ? 1 : 0.75,
        ...headingSx,
      }}
      aria-label={label}
    >
      <InnerTag sx={{ display: "inline-block" }} variants={textMotionVariants}>
        {children}
      </InnerTag>
    </Tag>
  )
}

Heading.defaultProps = {
  hLevel: 2,
  fullOpacity: false,
  noAnimate: false,
  label: "",
}

export default Heading
