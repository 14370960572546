/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line no-unused-vars
import React from "react"
import { motion } from "framer-motion"
import Header from "../components/header"
import Footer from "../components/footer"
import Container from "../components/container"

const MotionWrapper = ({ children, footerLinks }) => {
  return (
    <motion.main
      initial="initial"
      animate="enter"
      exit="exit"
      variants={{
        exit: { transition: { staggerChildren: 0 } },
        enter: { transition: { staggerChildren: 0.15 } },
      }}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "auto",
        minHeight: "100vh",
        ".slab-standard:last-of-type": {
          flex: 1,
        },
      }}
    >
      <Header />
      {children}
      <Footer footerLinks={footerLinks} />
    </motion.main>
  )
}

export default MotionWrapper
